import { REQUEST_TYPES, REQUEST_LOCATIONS } from '.';

export const logoutRequest = () => ({
  type: `[API] Logout Request`,
  location: REQUEST_LOCATIONS.FORGEROCK,
  url: '/revokeToken',
  APIMethod: REQUEST_TYPES.POST,
  headers: {
    'Accept-API-Version': 'resource=2.0,protocol=1.0',
  },
  body: {
    refreshToken: localStorage.getItem('refresh_token'),
  },
});

export const keepAliveRequest = url => ({
  type: `[API] KeepAlive Request`,
  location: '',
  url,
  APIMethod: REQUEST_TYPES.POST,
  body: {
    token: localStorage.getItem('refresh_token'),
  },
});

export const checkForSignInAccess = value => ({
  type: `[API] Temporary Check for Sign In Param`,
  url: `/temp/allowSignIn?allowId=${value}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getTokenRequest = () => ({
  type: `[API] Generate Token`,
  url: '/socure/generateToken',
  APIMethod: 'POST',
  body: {
    applicationId: 'TxT',
    uniqueIdentifier: window.crypto.randomUUID(),
  },
});

export const getSDKKeyRequest = (token, uniqueIdentifier) => ({
  type: `[API] Retrieve SDK Keys`,
  url: '/socure/retrieveKeys',
  APIMethod: 'POST',
  headers: {
    Authorization: token,
  },
  body: {
    applicationId: 'TxT',
    uniqueIdentifier,
  },
});