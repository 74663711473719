// Vendors
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from '../../../../hooks';

// Actions
import { getPaymentFormFrameUrl } from '../../../../reducers/payment/paymentActions';
import { goBack, push } from 'connected-react-router';

// Helpers
import { selectPathname } from '../../../../reducers/route/routeSelectors';

// Components
import { LoadingDots } from '../../../common';
import { Trans } from '../../../../components';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';
import { TxButton, TxButtonMode } from 'texkit-ui';

// Helpers
import { scrollToTop, getPaymentRoute } from '../../../../lib/helpers';
import { clearAlertInfo } from '../../../../reducers/alert/alertActions';

const PaymentForm = () => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const [url, setUrl] = useState();
  const [ignoreFrameError, setIgnoreFrameError] = useState(false);
  const [submitting, setSubmitting] = useState();
  const { selectedLanguage } = useSelector(({ language }) => ({
    selectedLanguage: language.selected,
  }));

  useAsyncEffect(() => dispatch(getPaymentFormFrameUrl()).then(setUrl), [
    dispatch,
  ]);

  const handleSuccess = () => {
    dispatch(clearAlertInfo());
    setSubmitting(true);

    // Setting ignoreFrameError to true after a handledSuccess allows us to temporarily catch errors from our
    // /transact call and handle those separately without a frame error throwing a Houston error modal

    setIgnoreFrameError(true);
    dispatch(push(getPaymentRoute('/review', currentPath)));
  };

  const handleError = () => {
    scrollToTop();
    setIgnoreFrameError(false);
    dispatch(getPaymentFormFrameUrl()).then(setUrl);
  };

  const handleCancel = () => {
    dispatch(clearAlertInfo());
    dispatch(goBack());
  };

  return (
    <>
      {submitting ? (
        <LoadingDots />
      ) : (
        <TxPaymentCardForm
          acceptedCards={['VISA', 'MasterCard', 'Discover', 'American Express']}
          billingAddressTitle={
            <Trans file="Payment" id="BillingAddressTitle" />
          }
          billingAddressSubTitle={
            <Trans file="Payment" id="BillingAddressSubTitle" />
          }
          iFrameUrl="about:blank"
          mode={TxPaymentCardFormMode.PaymentAdd}
          onSubmit={() => {}}
          onTokenReceived={() => {}}
          paymentMethodTitle={<Trans file="Payment" id="PaymentMethodTitle" />}
          paymentMethodSubTitle={
            <Trans file="Payment" id="PaymentMethodSubTitle" />
          }
          spanish={selectedLanguage === 'es'}
          showSaveCardOption
          saveCardOptionLabel={<Trans file="Payment" id="SaveCardToAccount" />}
          renderFormActions={(isValid, isSubmitting) => (
            <>
              <TxButton
                mode={TxButtonMode.Primary}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                <Trans file="Payment" id="PayNow" />
              </TxButton>
              <TxButton mode={TxButtonMode.Secondary}>
                <Trans file="Labels" id="Back" fallback="Back" />
              </TxButton>
            </>
          )}
        />
      )}
    </>
  );
};

export default PaymentForm;
