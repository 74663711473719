//@flow

// Vendors
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

// Components
import { Modal, Trans } from '../..';
import { TxAlert, TxAlertType } from 'texkit-ui/components';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import { getPaymentAccounts } from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';

import './edit-payment-account-modal.scss';

const EditPaymentAccountModal = () => {
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const { selectedLanguage, paymentAccount } = useSelector(
    ({ modal, language }) => ({
      modal: modal || {},
      paymentAccount: (modal && modal.data && modal.data.paymentAccount) || {},
      selectedLanguage: language.selected,
    })
  );

  const initialValues = useMemo(() => {
    const [month, year] =
      (paymentAccount.expirationDate &&
        paymentAccount.expirationDate.split('/')) ||
      [];

    const address = paymentAccount.address || {};
    return {
      cardNumber: paymentAccount.cardNumber,
      expirationDate: `${month}/20${year}`,
      streetAddress: address.address1,
      streetAddress2: address.address2,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
    };
  }, [paymentAccount.cardNumber]);

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dismissModal();
  };

  const handleError = () => {
    setHasError(true);
  };

  const dismissModal = () => {
    dispatch(closeModal('EditPaymentAccountModal'));
    setHasError(false);
  };

  return (
    <Modal
      name="EditPaymentAccountModal"
      className="edit-payment-account-modal"
      disableOnClickOutside="true"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Payment"
          id="UpdateCardInformation"
          fallback="Update card information"
        />
      </h2>
      <TxPaymentCardForm
        acceptedCards={['VISA', 'MasterCard', 'Discover', 'American Express']}
        billingAddressTitle={<Trans file="Payment" id="BillingAddressTitle" />}
        billingAddressSubTitle={
          <Trans file="Payment" id="BillingAddressSubTitle" />
        }
        iFrameUrl="about:blank"
        mode={TxPaymentCardFormMode.Edit}
        onSubmit={() => {}}
        onTokenReceived={() => {}}
        paymentMethodTitle={<Trans file="Payment" id="CardInformationTitle" />}
        paymentMethodSubTitle={
          <Trans file="Payment" id="PaymentMethodSubTitle" />
        }
        spanish={selectedLanguage === 'es'}
        initialValues={initialValues}
        renderFormActions={(isValid, isSubmitting) => (
          <TxButton
            mode={TxButtonMode.Primary}
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            <Trans file="Payment" id="SavePaymentMethod" />
          </TxButton>
        )}
      />
      {hasError && (
        <TxAlert
          className="iframe-error"
          type={TxAlertType.Warning}
          heading={<Trans file="Payment" id="UnableToUpdateCard" />}
        >
          <Trans file="Payment" id="TryAgain" />
        </TxAlert>
      )}
    </Modal>
  );
};

export default EditPaymentAccountModal;
