//@flow

// Vendors
import React, { useEffect, useState, type Context } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getPaymentAccounts } from '../../reducers/payment/paymentActions';

// Helpers
import { scrollToTop, getPaymentRoute } from '../../lib/helpers';
import { selectPathname } from '../../reducers/route/routeSelectors';

// Components
import { Trans, LoadingDots, StepperWithRoutes } from '..';
import { steps, PAYMENT_STEPS } from './PaymentSteps';

// Styles
import './payment.scss';
import { replace } from 'connected-react-router';
import { PaymentFailureModal } from './../../components';

type Props = {
  onSuccess: Function,
  onError: Function,
};

export type PaymentContextObject = { onSuccess?: Function, onError?: Function };

export const PaymentContext: Context<PaymentContextObject> = React.createContext(
  {}
);

const Payment = ({ onSuccess, onError }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const currentPath = useSelector(selectPathname);

  useEffect(() => {
    dispatch(getPaymentAccounts())
      .then(paymentAccounts => {
        setLoading(false);
        if (!paymentAccounts || (paymentAccounts && !paymentAccounts.length)) {
          dispatch(replace(getPaymentRoute('/form', currentPath)));
        }
      })
      .catch(() => {
        dispatch(replace('/dashboard'));
      });
  }, [dispatch]);

  return (
    <>
      <PaymentContext.Provider value={{ onSuccess, onError }}>
        <div className="payment">
          {loading ? (
            <LoadingDots />
          ) : (
            <StepperWithRoutes
              transition="SlideTransition"
              steps={steps}
              defaultStepId={PAYMENT_STEPS.SELECT_PAYMENT}
              onStepChange={scrollToTop}
            />
          )}
        </div>
      </PaymentContext.Provider>
      <PaymentFailureModal />
    </>
  );
};

export default Payment;
